const colors = {
  blue: '#0e088b',
  lightBlue: '#2525d6',
  black: '#161624',
  shadowBlack: 'rgba(22, 22, 36, 0.24)',
  lightBlack: '#2C2C38',
  white: '#fff',
  red: '#FF2247',
  green: '#15730b',
  grey: '#f2f2f4',
  darkGrey: '#d8d8d8',
  darkerGrey: '#b5b5b5',
};

const theme = {
  text: {
    standard: colors.black,
    link: colors.lightBlue,
    inverted: colors.white,
    sold: colors.red,
    price: colors.green,
    footer: colors.lightBlack,
  },
  background: {
    primary: colors.blue,
    standard: colors.grey,
    secondary: colors.darkGrey,
    tertiary: colors.white,
  },
  carCard: {
    meta: colors.grey,
    title: colors.blue,
  },
  nav: {
    background: colors.white,
    border: colors.red,
  },
  form: {
    background: colors.white,
    shadow: colors.shadowBlack,
    button: colors.blue,
    buttonText: colors.white,
  },
};

export default theme;
