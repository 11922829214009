import styled from 'styled-components';

const ContentTitle = styled.h1`
  font-size: 56px;
  line-height: 70px;
`;

const Main = styled.span`
  flex: 1;
`;

const SubText = styled.span`
  font-size: 1rem;
`;

ContentTitle.Main = Main;
ContentTitle.SubText = SubText;

export default ContentTitle;
