import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle, theme } from '../styles';
import Header from './Header';
import Footer from './Footer';

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSmallHeader: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { isPassive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, {
      isPassive: true,
    });
  }

  handleScroll(e) {
    const scrollY = window.scrollY;
    if (this.props.isIndex) {
      let changeHeight = 617;

      if (window.innerWidth <= 480) {
        changeHeight = 399;
      }

      this.setState({ isSmallHeader: window.scrollY >= changeHeight });
    }
  }

  render() {
    const { children, isIndex } = this.props;
    const { isSmallHeader } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle />
              <Helmet
                defaultTitle={data.site.siteMetadata.title}
                titleTemplate={`%s - ${data.site.siteMetadata.title}`}
                meta={[
                  {
                    name: 'description',
                    content:
                      "Limerence Motor Company is a licensed dealership and importer of classic Japanese domestic vehicles, located on Florida's Suncoast. We have an eye for all original examples with low miles, manual transmissions, turbo engines and rad decals.",
                  },
                  {
                    name: 'keywords',
                    content:
                      'japan domestic market, Japanese classic imports, right hand drive automobiles, turbo diesel 4x4 offroad, US legal imports, classic 90s cars, classic 80s cars, classic Japanese cars, direct from Japan vehicles, all original imports',
                  },
                ]}
              >
                <html lang="en" />
                <link
                  href="https://fonts.googleapis.com/css?family=Muli:400,600,700,800"
                  rel="stylesheet"
                />
                <script
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=UA-131507803-1"
                />
                <script>
                  {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'UA-131507803-1');
                  `}
                </script>
              </Helmet>
              <Header
                siteTitle={data.site.siteMetadata.title}
                isSmall={isSmallHeader}
                isForcedSmall={!isIndex}
              />
              <div
                style={{
                  margin: '0 auto',
                  maxWidth: 1300,
                  padding: '0px 1.0875rem 1.45rem',
                  paddingTop: 0,
                }}
              >
                {children}
              </div>
              <Footer />
            </>
          </ThemeProvider>
        )}
      />
    );
  }
}

Layout.propTypes = {
  isIndex: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  isIndex: false,
};

export default Layout;
