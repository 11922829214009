import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { devices } from '../../styles';
import logo from '../../images/logo.svg';
import logoSmall from '../../images/logo_small.svg';
import headerSplash from '../../images/headersplash.jpg';
import headerSplashMobile from '../../images/headersplash_mobile.jpg';
import instagramIcon from '../../images/instagram.svg';
import Icon from '../Icon';

const Logo = styled.img`
  width: 200px;
  height: 200px;
  margin: auto;
`;

const SmallLogo = styled(Link)`
  display: inline-block;
  width: 144px;
  height: 48px;
`;

const Navigation = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: ${({ theme }) => theme.nav.background};
  z-index: 1000;
  bottom: -80px;
  width: 100%;
  height: 80px;
  transition: height 0.18s ease-in;
`;

const NavButton = styled.button`
  cursor: pointer;
  position: absolute;
  outline: 0 none;
  top: 18px;
  right: 18px;
  display: none;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.text.standard};
  z-index: 10000;
  border: 0;
  background-color: transparent;
  font-size: 35px;
`;

const NavItem = styled.li`
  margin: auto 20px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.nav.background};

  &:last-child {
    margin-left: auto;
  }

  @media ${devices.mobile} {
    margin: auto 10px;
  }

  & > a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.text.standard};
    padding: 0 13px;
    font-size: 1.4rem;
    font-weight: bold;
    transition: background-color 0.18s ease-in-out;
    font-family: proxima-nova-condensed, sans-serif;

    @media ${devices.mobile} {
      font-size: 20px;
      padding: 0 10px;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => lighten(0.1, theme.text.link)};
    }
  }
`;

const InstagramIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 617px;
  margin: 0 auto 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.background.secondary};
  background-image: url(${headerSplash});
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10000;

  @media ${devices.mobile} {
    height: 399px;
    background-image: url(${headerSplashMobile});
  }

  > a {
    display: block;
    width: 200px;
    height: 200px;
    margin: auto;
  }

  ${SmallLogo} {
    display: none;
  }

  ${NavItem}:first-child {
    display: none;
  }

  ${NavItem}:last-child {
    display: none;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      background-color: transparent;

      ${Logo} {
        display: none;
      }

      ${Navigation} {
        top: 0;
        position: fixed;
        justify-content: left;
        border-bottom: 4px solid ${({ theme }) => theme.nav.border};

        @media ${devices.mobile} {
          flex-wrap: wrap;
          overflow-y: ${({ isMenuOpen }) =>
            isMenuOpen ? 'visible' : 'hidden'};
        }
      }

      ${NavButton} {
        @media ${devices.mobile} {
          display: block;
        }
      }

      ${SmallLogo} {
        display: flex;
      }

      ${NavItem} {
        @media ${devices.mobile} {
          margin: auto 0;
          width: 100%;
          height: 80px;
          border: 0;
        }
      }

      ${NavItem}:first-child {
        display: flex;
      }

      ${NavItem}:last-child {
        display: flex;
        text-align: center;

        @media ${devices.mobile} {
          border-bottom: 4px solid ${({ theme }) => theme.nav.border};
        }

        > a {
          margin: 0 auto;
        }
      }
    `};

  ${({ isForcedSmall }) =>
    isForcedSmall &&
    css`
      background-color: transparent;
      background-image: none;
      height: 80px;

      > a {
        display: none;
      }

      @media ${devices.mobile} {
        height: 80px; /* This is here to override the default media mobile property */
      }

      ${Logo} {
        display: none;
      }

      ${Navigation} {
        top: 0;
        position: fixed;
        justify-content: left;
        border-bottom: 4px solid ${({ theme }) => theme.nav.border};

        @media ${devices.mobile} {
          flex-wrap: wrap;
          overflow-y: ${({ isMenuOpen }) =>
            isMenuOpen ? 'visible' : 'hidden'};
        }
      }

      ${NavButton} {
        @media ${devices.mobile} {
          display: block;
        }
      }

      ${SmallLogo} {
        display: flex;
      }

      ${NavItem} {
        @media ${devices.mobile} {
          margin: auto 0;
          width: 100%;
          height: 80px;
          border: 0;
        }
      }

      ${NavItem}:first-child {
        display: flex;
      }

      ${NavItem}:last-child {
        display: flex;

        @media ${devices.mobile} {
          border-bottom: 4px solid ${({ theme }) => theme.nav.border};
        }

        > a {
          margin: 0 auto;
        }
      }
    `};
`;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  toggleMenu = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });

  render() {
    const { siteTitle, isSmall, isForcedSmall } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <HeaderContainer
        isSmall={isSmall}
        isForcedSmall={isForcedSmall}
        isMenuOpen={isMenuOpen}
      >
        <Link to="/">
          <Logo src={logo} alt={siteTitle} />
        </Link>
        <Navigation isMenuOpen={isMenuOpen}>
          <NavItem>
            <SmallLogo to="/">
              <img src={logoSmall} alt={siteTitle} />
            </SmallLogo>
          </NavItem>
          <NavButton onClick={() => this.toggleMenu()}>
            {isMenuOpen ? <Icon name="times" /> : <Icon name="bars" />}
          </NavButton>
          <NavItem>
            <Link to="/" activeClassName="active">
              Inventory
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about-us" activeClassName="active">
              About
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/contact" activeClassName="active">
              Contact Us
            </Link>
          </NavItem>
          <NavItem>
            <a
              href="https://www.instagram.com/limerencemotorco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon src={instagramIcon} alt="Our Instagram" />
            </a>
          </NavItem>
        </Navigation>
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isSmall: PropTypes.bool.isRequired,
  isForcedSmall: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
