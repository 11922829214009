import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ sizes, alt, className }) => (
  <Img className={className} sizes={sizes} alt={alt} />
);

Image.propTypes = {
  sizes: PropTypes.object.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  alt: '',
};

export default Image;
