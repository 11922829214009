import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FooterContainer = styled.div`
  max-width: 1070px;
  margin: 30px auto;
  text-align: center;
  padding-top: 30px;
  color: ${({ theme }) => theme.text.footer};
  font-size: 16px;
  line-height: 20px;
`;

const Nav = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
`;

const Item = styled.li`
  margin: 0;
  display: inline-block;
  border-right: 1px solid ${({ theme }) => theme.text.footer};

  &:last-child {
    border-right: none;
  }

  & > a {
    padding: 5px 10px;
    color: ${({ theme }) => theme.text.link};
  }
`;

const Footer = () => (
  <FooterContainer>
    <Nav>
      <Item>
        <Link to="/">Inventory</Link>
      </Item>
      <Item>
        <Link to="/about-us">About</Link>
      </Item>
      <Item>
        <Link to="/contact">Contact Us</Link>
      </Item>
    </Nav>
    Copyright &copy; Limerence Motor Co. 2022. All Rights Reserved.
  </FooterContainer>
);

export default Footer;
