import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faBars,
  faTimes,
  faCar,
  faEnvelope,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleRight,
  faBars,
  faTimes,
  faCar,
  faEnvelope,
  faAngleLeft,
  faSearchPlus
);

const Icon = ({ name }) => <FontAwesomeIcon icon={name} />;

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
