const breakpoints = {
  mobile: 480,
};

export const devices = {
  mobile: `(max-width: ${breakpoints.mobile}px)`,
};

export { default as theme } from './theme';
export { default as GlobalStyle } from './globalStyle';
